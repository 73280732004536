import { Notifier as AirbrakeNotifier } from '@airbrake/browser'
import * as Sentry from '@sentry/react'
import localNotifier from './local-notifier'

const AIRBRAKE_PROJECT_ID = process.env.NEXT_PUBLIC_AIRBRAKE_PROJECT_ID
const AIRBRAKE_PROJECT_KEY = process.env.NEXT_PUBLIC_AIRBRAKE_PROJECT_KEY
const SENTRY_PROJECT_URL = process.env.NEXT_PUBLIC_SENTRY_PROJECT_URL
const environment = process.env.NODE_ENV

const googleSearchAppError = "Can't find variable: gmo"

Sentry.init({
  dsn: SENTRY_PROJECT_URL,
  tracesSampleRate: 1,
  debug: false,
  environment,
  ignoreErrors: [googleSearchAppError],
})

const needErrorReporting = ['qa', 'production'].includes(environment)

const airbrake = needErrorReporting ? new AirbrakeNotifier({
  projectId: AIRBRAKE_PROJECT_ID,
  projectKey: AIRBRAKE_PROJECT_KEY,
  environment,
}) : localNotifier

airbrake.addFilter((notice) => {
  // This seems to be a problem with users arriving from the Google Search App, but we can't seem to replicate
  if (notice.errors[0].message === googleSearchAppError) {
    return null
  }

  return notice
})

const sentry = (error, context, reactErrorInfo) => {
  if (!needErrorReporting) { return }

  Sentry.withScope((scope) => {
    scope.setContext('Context', context)

    if (reactErrorInfo) {
      scope.setContext('Stacktrace', reactErrorInfo?.componentStack)
    }

    if (typeof error === 'string') {
      Sentry.captureMessage(error)
    } else {
      Sentry.captureException(error)
    }
  })
}

export default airbrake
export { sentry }
